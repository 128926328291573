
import './App.css';
 import PrivacyPolicy from './components/PrivacyPolicy';
// import { MyPlugin } from "./components/NEW1";
function App() {
  return (
    <div className="App">
    <PrivacyPolicy /> 
     {/* <MyPlugin />  */}
  </div>
  );
}

export default App;
